<template>
  <el-dialog
    title="LED 스케줄 다운로드"
    :visible.sync="isShowDialog"
    @close="closeModal"
    append-to-body
    :closeOnClickModal="false"
    top="5vh"
    class="el-dialog-aside"
    width="1100px"
  >
    <el-container>
      <el-container>
        <el-row style=" border-top: 1px solid rgb(222, 222, 222); height: 550px; width: 100%;">
          <el-col :span="11" class="led-schedule-wrap">
            <h4>현재 스케줄</h4>
            <div v-if="ledDownloadedSchedule" class="led-schedule-body">
              <div v-for="(item, seq) in ledDownloadedSchedule.ledMessages" :key="seq" class="led-message">
                <div class="led-message-image">
                  <led-preview-viewer :ledmessage="item" :scale="2"></led-preview-viewer>
                  <!-- <img :src="item.thumbImageFile" /> -->
                </div>
                <div class="led-message-desc">
                  <div>{{ item.ledMessageName }}</div>
                  <div>{{ item.displayTime }}초</div>
                </div>
              </div>
            </div>
            <div v-else class="led-schedule-body">현재 설정된 LED 스케줄이 없습니다.</div>
          </el-col>
          <el-col :span="2" class="arrow-button-wrap">></el-col>
          <el-col :span="11" class="led-schedule-wrap">
            <h4>설정 예정 스케줄</h4>
            <div v-if="selectedLedSchedule" class="led-schedule-body">
              <div v-for="(item, seq) in selectedLedSchedule.ledMessages" :key="seq" class="led-message">
                <div class="led-message-image">
                  <led-preview-viewer :ledmessage="item" :scale="2"></led-preview-viewer>

                  <!-- <img :src="item.thumbImageFile" /> -->
                </div>
                <div class="led-message-desc">
                  <div>{{ item.ledMessageName }}</div>
                  <div>{{ item.displayTime }}초</div>
                </div>
              </div>
            </div>
            <div v-else class="led-schedule-body">LED 스케줄 목록을 선택해주세요</div>
          </el-col>
        </el-row>
      </el-container>
      <el-aside width="250px">
        <div class="info-panel">
          <el-table
            :data="ledSchedule"
            style=" border-top: 1px solid #dedede; width: 100%;"
            empty-text="등록된 LED 스케줄이 없습니다."
            size="mini"
            stripe
            :height="480"
            @row-click="selectLedSchedule"
          >
            <el-table-column prop="ledScheduleName" label="LED 스케줄 이름" min-width="120px"></el-table-column>
            <el-table-column prop="ledMessageSize" label="LED 사이즈" min-width="100px"></el-table-column>
          </el-table>
          <div class="led-schedule-button-wrap">
            <el-button @click="clickDownloadLedSchedule">스케줄 설정</el-button>
          </div>
        </div>
      </el-aside>
    </el-container>
  </el-dialog>
</template>

<script>
import ledPreviewViewer from '../led/form/ledPreviewViewer.vue';

export default {
  components: {
    'led-preview-viewer': ledPreviewViewer,
  },
  data() {
    return {
      ledSchedule: [],
      ledDownloadedSchedule: null,
      isShowDialog: false,
      selectedLedSchedule: null,
      selectedFcltData: null,
    };
  },
  methods: {
    async getLedSchedule(row) {
      const width = row.fcltTypeData.ledWidth.value || '6';
      const height = row.fcltTypeData.ledHeight.value || '1';

      let param = { ledMessageSize: `${height}x${width}` };
      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledSchedule`, { params: param });
        this.ledSchedule = response.data.docs || [];
      } catch (err) {}
    },
    async getLedDownloadedSchedule(ledData) {
      let param = {
        fcltId: ledData.fcltId,
      };

      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledDownloadedSchedule`, { params: param });
        if (response.data) {
          this.ledDownloadedSchedule = response.data.docs || null;
        } else {
          this.ledDownloadedSchedule = null;
        }
      } catch (err) {}
    },
    selectLedSchedule(row) {
      this.selectedLedSchedule = row;
    },
    showDialog(row) {
      this.isShowDialog = true;
      this.selectedFcltData = row;
      this.getLedDownloadedSchedule(row);
      this.getLedSchedule(row);
    },
    closeModal() {
      this.ledDownloadedSchedule = null;
      this.selectedLedSchedule = null;
      this.selectedFcltData = null;
    },
    async clickDownloadLedSchedule() {
      if (!this.selectedLedSchedule) {
        this.$message({
          type: 'warning',
          message: `설정할 LED 스케줄을 선택해주세요`,
        });
        return;
      }
      let param = { ...this.selectedLedSchedule, ...this.selectedFcltData };

      try {
        this.$confirm(`${this.selectedLedSchedule.ledScheduleName} LED 스케줄을 설정 합니다.`, '경고', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'info',
        })
          .then(async () => {
            this.loading = this.$loading({
              lock: true,
              text: 'LED 스케줄을 설정 합니다',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            });
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledDownloadedSchedule/proc`, { data: param });
            this.loading.close();
            this.isShowDialog = false;
            this.$emit('completeLedSchedule', {
              fcltId: param.fcltId,
            });
          })
          .catch(() => {
            this.loading.close();
            this.$message({
              type: 'info',
              message: `${this.selectedLedSchedule.ledScheduleName} LED 스케줄 설정 취소.`,
            });
          });
      } catch (err) {}
    },
  },
};
</script>

<style scoped>
.led-schedule-wrap,
.arrow-button-wrap {
  height: 100%;
  overflow-y: auto;
  text-align: center;
}
.arrow-button-wrap {
  background-color: rgb(222, 222, 222);
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
}
.led-schedule-wrap h4 {
  margin: 14px 0 14px 10px;
  text-align: left;
}
.led-schedule-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.led-schedule-body .led-message {
  border-bottom: 1px solid rgb(233, 233, 233);
  height: 100px;
  position: relative;
}
.led-schedule-body .led-message .led-message-image {
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding-top: 15px;
  position: absolute;
  right: 100px;
  text-align: left;
  top: 0;
}
.led-schedule-body .led-message .led-message-image img {
  height: 80%;
  width: auto;
}
.led-schedule-body .led-message .led-message-desc {
  bottom: 0;
  padding-top: 15px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  width: 95px;
}
.led-schedule-button-wrap {
  padding-right: 10px;
  padding-top: 10px;
  text-align: right;
}
</style>
